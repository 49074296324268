<template>
  <iframe ref="oldFrame" style="height: calc(100vh - 126px); width: 100%" frameborder="0" :src="frameUrl"></iframe>
</template>

<script>
export default {
  name: 'OldSystemFrame',
  props: {
    url: {
      type: String,
      default: ''
    },
  },
  computed: {
    frameUrl () {
      let url = this.url;
      if(typeof(this.$route.params.url) !== 'undefined'){
        url = window.atob(this.$route.params.url);
      }

      if(url.length && url.length > 0){
        return process.env.VUE_APP_OLD_URL + url;
      }
      else {
        return process.env.VUE_APP_OLD_URL + '/login/';
      }
    },
  },
  mounted: function () {
    this.$store.dispatch('setIntroJsEnabled', false);
    let parent = this.$parent;
    for(let x = 0; x <= 3; x++){
      if(typeof(parent) !== "undefined") {
        parent.container = false;
        parent = parent.$parent;
      }
    }
  },
  destroyed: function () {
    this.$store.dispatch('setIntroJsEnabled', true);
    let parent = this.$parent;
    for(let x = 0; x <= 3; x++){
      if(typeof(parent) !== "undefined") {
        parent.container = true;
        parent = parent.$parent;
      }
    }
  }
}
</script>
